.vapp-rate-utility {
  display: flex;
  direction: ltr !important;
  > div {
    position: relative;
    display: block;
    > span {
      color: #ffe244;
      direction: ltr !important;
      display: block;
      width: 20px;
    }
  }
  > div {
    span:last-child {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
    }
  }
}
