@import "../../references/index";

.vapp-pagination {
  width: 100%;
  margin: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: none;
    color: inherit;
  }

  .pagination-body {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0 20px 42px 0 rgba(22, 23, 52, 0.08);
    padding: 12px 24px;
    border-radius: 5px;

    .prev-button,
    .next-button {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      color: $bg2;

      i {
        font-size: 15px;
      }

      &.active:hover {
        background-color: #6a6a6a !important;
      }

      &.active {
        background-color: rgba($color: $text3, $alpha: 0.6);
        color: #ffffff;
      }
    }

    .pages {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      > p {
        padding: 0 18px;
      }

      a {
        font-size: 18px;
        font-weight: 300;
        color: $text3;
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.active {
          border: none;
          box-shadow: 0 0 10px 0 rgba(247, 148, 30, 0.2);
          background-color: $primary;
          width: 36px;
          height: 36px;
          color: #fcfcfc;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
