@use "sass:selector";
$primary: #171c8f;
$bg1: #f1f2f5;
$accentBlue: #00a3e0;
$text2: #7c7d9a;
$text3: #494a67;
$text4: #25275d;
$text5: #161734;
$text6: #8b8b9a;
$red: #ff678c;
$bg4: #626380;
$bg2: #cacbd9;
$black: #212121;
$lightBlue: #4ec3e0;
