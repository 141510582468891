@import "../../references/index.scss";

.vapp-input {
  width: 100%;
  z-index: 1;
  margin-bottom: 12px;

  &.fill {
    label {
      font-size: 14px !important;
      color: $text4 !important;
    }
    .input-body i {
      color: $text4 !important;
    }
  }

  &.error {
    .input-body {
      border: rgba($red, 0.8) 1px solid !important;
    }

    label {
      color: $red;
    }

    i {
      color: $red;
    }
  }

  > label {
    font-size: 14px;
    color: $text4;
    margin-bottom: 10px;
    font-weight: normal;
  }

  .input-body {
    border-radius: 4px;
    display: flex;
    position: relative;
    background-color: #ffffff;
    padding: 0 18px;

    input {
      text-align: right;
      flex: 1;
      border: none;
      align-self: flex-end;
      background-color: transparent !important;
      font-family: IRANSans !important;
      width: 100%;
      height: 42px;
      outline: none;
      position: relative;
      font-weight: normal;
      color: $text4;
      font-size: 14px;
      -moz-appearance: textfield;

      &[type="number"]::-webkit-outer-spin-button,
      &[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &::placeholder {
        font-family: IRANSans;
        font-size: 14px;
        font-weight: normal;
        color: $text2;
      }
    }

    i {
      font-size: 18px;
      color: $text2;
      align-self: center;
    }
  }

  .error-text {
    color: $red;
    font-size: 12px;
    width: 100%;
    display: block;
    text-align: right;
  }
}
