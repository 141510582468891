@import "../../utilities/references";

.vapp-login-page{
    display: flex;
    min-height: 100vh;
    
    .phone-box,
    .code-box{
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 36px;
        justify-content: center;
        align-items: center;
        
        @include vapp-sm{
            max-width: 336px;
        }
        >h2{
            font-size: 24px;
            color: $text4;
            padding-bottom: 36px;
            font-weight: bold;
        }

        >button{
            margin: 24px 0 18px;
        }

        .form-tools{
            display: flex;

            >p{
                font-size: 14px;
                color: $text3;
                padding: 0 6px;
                pointer-events: none;

                &.active{
                    pointer-events: unset;
                    color: $lightBlue;
                    cursor: pointer;
                }
            }
            >a , >span{
                font-size: 14px;
                color: $lightBlue;
                padding: 0 6px;
            }
        }
    }

    .register-cover{
        position: relative;
        flex: 1;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1{
            color: white;
            z-index: 2;
            position: relative;        
            padding: 24px;
            font-size: 40px;
            font-weight: bold;
        }
        h2{
            color: white;
            z-index: 2;
            font-weight: 600;
            position: relative;
            font-weight: bold;
            padding: 24px;
            font-size: 24px;
        }

        >img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            top: 0;
            left: 0;
            z-index: 1;
            filter: brightness(60%) blur(1.3px) contrast(1.5);
        }
    }
}