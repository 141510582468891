@import "../references/index";

.un-style-button {
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-family: IRANSans;
}

.vapp-button {
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: IRANSans;
  text-decoration: none;

  &:disabled {
    opacity: 0.5;
  }

  &.vapp-gradient-button {
    background-image: linear-gradient(103deg, $primary 2%, $accentBlue 98%);
    color: white;
  }

  &.vapp-blue-button {
    background-color: $lightBlue;
    color: white;
    box-shadow: 0 18px 48px 0 rgba(78, 195, 224, 0.35);
  }

  &.vapp-boarded-button {
    border: solid 1px $primary;
    color: $primary;
  }

  &.vapp-small-button {
    height: 28px !important;
    font-size: 12px !important;
    border-radius: 4px !important;
  }

  /*   &.vapp-orange-button {

    background-color: $soloOrange;
    box-shadow: 0 5px 25px 0 rgba(247, 148, 30, 0.22);
    color: #fcfcfc;

    :hover {
      box-shadow: 0 15px 40px 0 rgba(247, 148, 30, 0.22);
    }

    :active {
      background-color: #fbab4c;
    }
  }

  &.vapp-white-button {
    background-color: #ffffff;
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.05);
    color: $darkGray;

    :hover {
      box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.05);
    }

    :active {
      background-color: #fcfcfc;
    }
  }

  &.vapp-gray-button {
    background-color: $white;
    color: #6a6a6a;

    :active {
      background-color: $lightGray;
    }
  }
 */
  &.vapp-rounded-button {
    border-radius: 24px;
    height: 42px;
    font-size: 14px;
  }

  &.vapp-square-button {
    border-radius: 5px;
    height: 42px;
    padding: 0 18px;
    font-size: 14px;
  }
}
