@import "../../references/index.scss";

.vapp-dropdown {
  width: 100%;
  margin-bottom: 12px;

  &.fill {
    label {
      color: $text4 !important;
    }

    .dropdown-body > p,
    .dropdown-body > i {
      color: $text4 !important;
    }
  }

  &.open {
    label {
      color: $text4 !important;
    }

    .dropdown-body {
      border-radius: 5px 5px 0 0;
      box-shadow: 0 20px 42px 0 rgba(22, 23, 52, 0.08);
      > ul {
        padding: 12px 0;
        border-top: 1px solid $lightBlue;
        max-height: 200px;
        opacity: 1;
        overflow-y: auto;
      }
    }
  }

  &.error {
    label {
      color: $red;
    }

    .dropdown-body {
      border: rgba($red, 0.8) 1px solid !important;
      > i {
        color: $red;
      }
    }
  }

  > label {
    font-size: 14px;
    color: $text4;
    margin-bottom: 10px;
    font-family: IRANSans;
    font-weight: normal;
  }

  .dropdown-body {
    border-radius: 4px;
    // border: rgba($text2, 0.5) 1px solid;
    display: flex;
    position: relative;
    background-color: #ffffff;
    padding: 0 18px;
    height: 42px;
    align-items: center;
    position: relative;

    > p {
      font-family: IRANSans;
      font-size: 14px;
      font-weight: normal;
      color: $text2;
      // opacity: 0.6;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      flex: 1;
    }

    > select {
      z-index: 1;
      width: 100%;
      position: absolute;
      width: 100%;
      height: 42px;
      top: 0;
      opacity: 0;
      left: 0;
    }

    > ul {
      padding: 0;
      position: absolute;
      top: 42px;
      background-color: white;
      max-height: 0;
      opacity: 0;
      width: 100%;
      overflow: hidden;
      left: 0;
      z-index: 10;
      border-radius: 0 0 5px 5px;
      box-shadow: 0 0 0 0 rgba(22, 23, 52, 0.08);
      margin: 0;
      transition: max-height ease-in-out 0.3s, opacity ease-in-out 0.3s,
        box-shadow ease-in-out 0.3s, padding ease-in-out 0.3s;

      li {
        list-style-type: none;
        padding: 0 12px;
        height: 40px;
        display: flex;
        color: $text3;
        align-items: center;
        font-size: 13px;
        cursor: pointer;

        &:hover {
          background-color: $bg1;
        }
      }
    }

    i {
      font-size: 18px;
      color: $text2;
      align-self: center;
    }
  }

  .error-text {
    color: $red;
    font-size: 12px;
    width: 100%;
    display: block;
    text-align: right;
  }
}
