@import "./cards.scss";
@import "./buttons.scss";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/dist/css/bootstrap-grid.min.css";
// @import "./icons";
// @import "../../../static/icons/feather/feather.css";
// @import "../../../static/icons/material/material.css";

body {
  padding: 0;
  margin: 0;
  direction: rtl;
  background-color: $bg1;

  &[dir="rtl"] {
    direction: rtl;

    * {
      direction: rtl;
      text-align: right;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  outline: none;
  text-decoration: none;
}

* {
  -webkit-tap-highlight-color: rgba($color: #000000, $alpha: 0);
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
input,
label,
div,
span,
button,
a,
p,
i,
ul,
li,
table,
tr,
td,
th,
tbody,
thead,
textarea {
  font-family: IRANSans !important;
  font-weight: 500;
}
