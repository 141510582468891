@import "../references/index.scss";

.vapp-scroll-vertical {
    position: relative;
    overflow-y: auto;
    // padding-bottom: 55px;

    &::after {
        content: "";
        bottom: 0;
        left: 0;
        width: 100%;
        height: 55px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.89) 62%, #ffffff);
    }
}

body,
.vapp-scroll-vertical {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar:hover {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background-color: #999999;
        // box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background-color:#999999;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        width: 50px;
        background-color: #999999;
    }

    &.scrolling {
        &::after {
            height: 10%;
            width: 100%;
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff 59%, #ffffff);
            bottom: 0;
            left: 0;
        }
    }
}

.vapp-loading-element {
    display: inline-block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    margin: 64px auto 64px auto;

    &::after {
        content: ' ';
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #171c8f;
        border-color: #171c8f transparent #171c8f transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }
}