@mixin vapp-xs {
  @media (max-width: 575.98px) {
      @content;
  }
}

@mixin vapp-sm {
  @media (min-width: 575.98px) {
      @content;
  }
}

@mixin vapp-md {
  @media (min-width: 767.98px) {
      @content;
  }
}

@mixin vapp-lg {
  @media (min-width: 991.98px) {
      @content;
  }
}

@mixin vapp-xl {
  @media (min-width: 1199.98px) {
      @content;
  }
}

@mixin vapp-pre-xs {
  @media (max-width: 575.98px) {
      @content;
  }
}

@mixin vapp-pre-sm {
  @media (max-width: 575.98px) {
      @content;
  }
}

@mixin vapp-pre-md {
  @media (max-width: 767.98px) {
      @content;
  }
}

@mixin vapp-pre-lg {
  @media (max-width: 991.98px) {
      @content;
  }
}

@mixin vapp-pre-xl {
  @media (max-width: 1199.98px) {
      @content;
  }
}

@mixin vapp-size($property, $web-value, $mobile-value,$important: "") {
  @include vapp-xs {
      #{$property}: $mobile-value #{$important};
  }

  @include vapp-sm {
      #{$property}: (2 * $mobile-value + $web-value)/3 #{$important};
  }

  @include vapp-md {
      #{$property}: ($mobile-value + $web-value)/2 #{$important};
  }

  @include vapp-lg {
      #{$property}: ($mobile-value + 2 * $web-value)/3 #{$important};
  }

  @include vapp-xl {
      #{$property}: $web-value #{$important};
  }
}

@mixin rtl {
  @at-root body[dir='rtl'] & {
      @content;
  }
}

@mixin ltr {
  @at-root body[dir='ltr'] & {
      @content;
  }
}
